.search {
    border: 2px solid black;
    padding: 28px;
    margin: auto;
    width: 300px;
}


  

