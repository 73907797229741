.forcast-container {
    margin: 20px;
}

.city-name {
    text-align: center;
    border-bottom: 1px dashed black;
}

.forcast-list {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.font {
    font-family: 'Oswald', sans-serif;
}