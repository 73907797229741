.single-day-card {
    text-align: center;
    background-color: #465E83;
    border: 2px solid black;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.single-day-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.icon {
    width: 100px; 
    height: 100px; 
  }

.date,
.weather-forecast,
.temp,
.feels-like,
.humidity {
    margin: 10px 0;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.font {
    font-family: 'Oswald', sans-serif;
}