.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    background-color: #F1DEDA !important;;
    padding: 20px;
    text-align: center; 
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px; 
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin: 0 0 20px 0; 
}

input[type="text"],
input[type="password"],
input[type="email"],
button[type="button"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button[type="button"] {
    background-color: #465E83;
    color: #fff;
    border: none;
    cursor: pointer;
}

button[type="button"]:hover {
    background-color: #0056b3;
}

.alert {
    margin-top: 10px;
}
