.container {
  padding: 0px !important;
}

.App {
  background: transparent;
}

body {
  background: url('../public/assets/imgs/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
}
