.forecast-card-wrapper {
    height: 325px;
    width: 175px;
    margin-right: 20px;
}

.forecast-card {
    padding: 10px;
    border: 1px solid black;
    background-color: #465E83;
    border-radius: 5px;
    height: 100%;
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.date {
    color: white;
}

.weather-forecast {
    color: white;
}

.temp {
    color: white;
}

.feels-like {
    color: white;
}

.humidity {
    color: white;
}

.view-link {
    color: white;
    display: block;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.font {
    font-family: 'Oswald', sans-serif;
}