.search {
    background-color: #F1DEDA !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.font {
    font-family: 'Oswald', sans-serif;
}